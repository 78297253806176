import request from "@/api/request";
// ----------------------------------------------------------------管理员管理
export function GetManages(params) { //获取管理员列表
  return request({
    url: "/api/SysManages/GetManages",
    method: "get",
    params
  });
}

export function Login(data) { //登录
  return request({
    url: "/api/SysManages/Login",
    method: "Post",
    data,
    json: 'json'
  });
}

export function Add(data) { //新增管理员
  return request({
    url: "/api/SysManages/Add",
    method: "Post",
    data,
    json: 'json'
  });
}

export function deleleClick(params) { //删除
  return request({
    url: "/api/SysManages/Delete",
    method: "delete",
    params,
  });
}

export function EnableOrDisable(params) { // 启用禁用
  return request({
    url: "/api/SysManages/EnableOrDisable",
    method: "get",
    params,
  })
}

export function AddRoleAndPermission(data) { //新增管理员
  return request({
    url: "/api/SysManages/AddRoleAndPermission",
    method: "Post",
    data,
    json: 'json'
  });
}

export function GetRoles(params) { // 获取角色
  return request({
    url: "/api/SysManages/GetRoles",
    method: "get",
    params,
  })
}
export function GetModulPermissions(params) { // 获取系统用户角色模块权限
  return request({
    url: "/api/SysManages/GetModulPermissions",
    method: "get",
    params,
  })
}
export function GetOneModulPermissions(params) { // 获取一级模块数据权限
  return request({
    url: "/api/SysManages/GetOneModulPermissions",
    method: "get",
    params,
  })
}
export function GetTwoModulDataPermissions(params) { // 获取二级模块数据权限
  return request({
    url: "/api/SysManages/GetTwoModulDataPermissions",
    method: "get",
    params,
  })
}

export function GetSysTakeNotes(params) { // 获取二级模块数据权限
  return request({
    url: "/api/SysManages/GetSysTakeNotes",
    method: "get",
    params,
  })
}

export function AddSysTakeNotes(data) { //修改
  return request({
    url: "/api/SysManages/AddSysTakeNotes",
    method: "post",
    data,
    json: 'json'
  });
}

export function Update(data) { //修改
  return request({
    url: "/api/SysManages/Update",
    method: "put",
    data,
    json: 'json'
  });
}

// ----------------------------------------------------------------首页统计（可视化）
export function Census(params) {//统计（可视化）
  return request({
    url: '/api/Census/GetCensus',
    method: 'get',
    params
  })
}

// ----------------------------------------------------------------页面管理
export function GetChatRecords(params) { //系统聊天记录列表
  return request({
    url: "/api/ChatRecords/GetChatRecords",
    method: "get",
    params
  });
}
export function SendAsync(params) { //发送回复线下会员聊天记录列表
  return request({
    url: "/api/ChatRecords/SendAsync",
    method: "get",
    params
  });
}
export function GetResponseChatRecords(params) { //回复线下会员聊天记录列表
  return request({
    url: "/api/ChatRecords/GetResponseChatRecords",
    method: "get",
    params
  });
}
export function ChatRecordAdd(data) { //新增回复线下会员聊天记录列表
  return request({
    url: "/api/ChatRecords/ChatRecordAdd",
    method: "post",
    data,
    json: "json"
  });
}
export function BlockState(params) { //拉黑状态
  return request({
    url: '/api/ChatRecords/BlockState',
    method: 'get',
    params,
  })
}
export function CheckBlockState(params) { //检查拉黑状态
  return request({
    url: '/api/ChatRecords/CheckBlockState',
    method: 'get',
    params,
  })
}
export function GetChatInRecords(params) { //回复线下会员聊天记录
  return request({
    url: "/api/ChatRecords/GetChatInRecords",
    method: "get",
    params
  });
}

export function GetSysPushInfos(params) { //回复线下会员聊天记录
  return request({
    url: "/api/SysPushInfo/GetSysPushInfos",
    method: "get",
    params
  });
}
export function GetVoiceCalls(params) { //获取音频通话列表
  return request({
    url: "/api/VoiceOFVideo/GetVoiceCalls",
    method: "get",
    params
  });
}

export function GetVideoCalls(params) { //获取视频通话列表
  return request({
    url: "/api/VoiceOFVideo/GetVideoCalls",
    method: "get",
    params
  });
}

export function UpdateAuditVideo(params) { //审核视频
  return request({
    url: "/api/VoiceOFVideo/UpdateAuditVideo",
    method: "put",
    params
  });
}

// ----------------------------------------------------------------页面管理
export function CarouselList(params) { //获取轮播列表
  return request({
    url: "/api/HomeModuls/CarouselList",
    method: "get",
    params
  });
}

export function HomeModulAdd(data) { //新增轮播
  return request({
    url: "/api/HomeModuls/Add",
    method: "Post",
    data,
    json: 'json'
  });
}

export function HomeModulUpdate(data) { //修改
  return request({
    url: "/api/HomeModuls/Update",
    method: "Put",
    data,
    json: 'json'
  });
}

export function HomeModulDelele(params) { //删除
  return request({
    url: "/api/HomeModuls/Delete",
    method: "delete",
    params,
  });
}

export function HomeModulEnable(params) { // 启用禁用
  return request({
    url: "/api/HomeModuls/EnableOrDisable",
    method: "get",
    params,
  })
}

export function GetModuls(params) {//获取系统菜单模块管理
  return request({
    url: "/api/sysmoduls/getmoduls",
    method: "get",
    params,
  })
}

// ----------------------------------------------------------------合同管理
export function GetContracts(params) { //获取合同
  return request({
    url: "/api/Contract/GetContracts",
    method: "get",
    params,
  })
}

export function GetActivityContracts(params) { //获取选择活动合同
  return request({
    url: "/api/Contract/GetActivityContracts",
    method: "get",
    params,
  })
}

export function AddContract(data) { //新增合同
  return request({
    url: "/api/Contract/Add",
    method: "post",
    data,
    json: "json"
  })
}

export function UpdateContract(data) { //更新合同
  return request({
    url: "/api/Contract/Update",
    method: "put",
    data,
    json: "json"
  })
}
export function DeleteContract(params) { //更新合同
  return request({
    url: "/api/Contract/DeleteContract",
    method: "delete",
    params
  })
}

export function GetUserContracts(params) { //获取合同用户列表
  return request({
    url: "/api/Contract/GetUserContracts",
    method: "get",
    params
  })
}

// ----------------------------------------------------------------订单管理
// export function GetRechargeSets(params) {//获取类型列表
//   return request({
//     url: "/api/RechargeSet/GetRechargeSets",
//     method: "get",
//     params
//   })
// }

// export function NewRechargeSet(data) { //新增充值类型
//   return request({
//     url: "/api/RechargeSet/NewRechargeSet",
//     method: "post",
//     data,
//     json: "json"
//   })
// }
// export function SetRechargeSet(data) { //更新充值类型
//   return request({
//     url: "/api/RechargeSet/SetRechargeSet",
//     method: "put",
//     data,
//     json: "json"
//   })
// }

// export function DeleteRechargeSet(params) { //删除充值类型
//   return request({
//     url: "/api/RechargeSet/DeleteRechargeSet",
//     method: "delete",
//     params
//   })
// }

export function GetOrders(params) { //获取订单列表
  return request({
    url: "/api/Orders/GetOrders",
    method: "get",
    params
  })
}

export function GetDeductions(params) { //获取扣费列表
  return request({
    url: "/api/Orders/GetDeductions",
    method: "get",
    params
  })
}

export function DeductionExport(params) { //导出扣费列表
  return request({
    url: "/api/Orders/DeductionExport",
    method: "get",
    params,
    responseType: 'blob'
  })
}

export function GetOrderReconciliations(params) { //获取对账列表
  return request({
    url: "/api/Orders/GetOrderReconciliations",
    method: "get",
    params
  })
}

export function OrderReconciliationExport(params) { //导出对账表格
  return request({
    url: "/api/Orders/OrderReconciliationExport",
    method: "get",
    params,
    responseType: 'blob'
  })
}

export function Verify(params) { //审核
  return request({
    url: "/api/Orders/Verify",
    method: "get",
    params
  })
}

export function OrdersExport(params) { //导出订单表格
  return request({
    url: "/api/Orders/OrdersExport",
    method: "get",
    params,
    responseType: 'blob'
  })
}

export function GetDeductionRule(params) { //获取扣费列表
  return request({
    url: "/api/Orders/GetDeductionRule",
    method: "get",
    params
  })
}

export function SaveDeductionRule(data) { //保存扣费规则
  return request({
    url: "/api/Orders/SaveDeductionRule",
    method: "post",
    data,
    json: "json"
  })
}
export function GetSysSalesNumbersAsync(params) { //获取绩效列表
  return request({
    url: "/api/SysSalesNumbers/GetSysSalesNumbersAsync",
    method: "get",
    params
  })
}

export function ExportSysSalesNumbersAsync(params) { //导出优惠码表格
  return request({
    url: "/api/SysSalesNumbers/ExportSysSalesNumbersAsync",
    method: "get",
    params,
    responseType: 'blob'
  })
}

export function UpdateSysSalesNumbers(data) { //保存扣费规则
  return request({
    url: "/api/SysSalesNumbers/UpdateSysSalesNumbers",
    method: "post",
    data,
    json: "json"
  })
}
// ----------------------------------------------------------------用户管理
export function GetUserViews(params) { //获取用户列表
  return request({
    url: "/api/Users/GetUserViews",
    method: "get",
    params
  })
}

export function UsersExport(params) { //导出用户信息表格
  return request({
    url: "/api/Users/UsersExport",
    method: "get",
    params,
    responseType: 'blob'
  })
}

export function UpdateUsers(data) { //更新会员信息
  return request({
    url: "/api/Users/UpdateUsers",
    method: "post",
    data,
    json: "json"
  })
}

export function FalseDelete(params) { //假删除
  return request({
    url: "/api/Users/FalseDelete",
    method: "delete",
    params,
  })
}
export function VerifyUserName(params) { //验证用户名
  return request({
    url: "/api/Users/VerifyUserName",
    method: "get",
    params,
  })
}
export function VerifyPhone(params) { //验证手机号
  return request({
    url: "/api/Users/VerifyPhone",
    method: "get",
    params,
  })
}
export function VerifyIDCard(params) { //验证身份证
  return request({
    url: "/api/Users/VerifyIDCard",
    method: "get",
    params,
  })
}
//新增其他国家城市
export function AddOtherCountry(data) {
  return request({
    url: "/api/Users/AddOtherCountry",
    method: "post",
    data,
    json: "json"
  })
}
//获取其他国家城市
export function GetSysOtherCountrys(params) {
  return request({
    url: "/api/Users/GetSysOtherCountrys",
    method: "get",
    params,
  })
}
//检查是否存在
export function CheckLabel(params) {
  return request({
    url: "/api/Users/CheckLabel",
    method: "get",
    params,
  })
}
// ----------------------------------------------------------------活动管理
export function GetAcitvitys(params) { //获取活动列表
  return request({
    url: "/api/Activitys/GetAcitvitys",
    method: "get",
    params
  })
}
export function DeleteActivity(params) { //删除活动
  return request({
    url: "/api/Activitys/DeleteActivity",
    method: "delete",
    params
  })
}
export function NewActivity(data) { //新的活动
  return request({
    url: "/api/Activitys/NewActivity",
    method: "post",
    data,
    json: "json"
  })
}

export function UpdateActivity(data) { //更新活动
  return request({
    url: "/api/Activitys/UpdateActivity",
    method: "post",
    data,
    json: "json"
  })
}
// ----------------------------------------------------------规则信息----------------------------- >>>


// ----------------------------------------------------------规则信息----------------------------- >>>
export function RuleAdd(data) { //新增
  return request({
    url: "/api/RuleInfo/RuleAdd",
    method: "post",
    data,
    json: "json"
  })
}

export function RuleUpdate(data) { //更新
  return request({
    url: "/api/RuleInfo/RuleUpdate",
    method: "post",
    data,
    json: "json"
  })
}
export function RuleDelete(params) { //删除
  return request({
    url: "/api/RuleInfo/RuleDelete",
    method: "delete",
    params
  })
}
export function GetRuleInfos(params) { //获取规则信息
  return request({
    url: "/api/RuleInfo/GetRuleInfos",
    method: "get",
    params
  })
}

//获取用户发布列表
export function GetPublishContents(params) {
  return request({
    url: "/api/PublishContents/GetPublishContents",
    method: "get",
    params
  })
}

//审核发布内容

//新增线下会员发布
export function addOfflineRelease(data) {
  return request({
    url: "/api/PublishContents/addofflinerelease",
    method: "post",
    data,
    json: "json"
  })
}
//获取线下会员
export function GetOfflineMembers(params) {
  return request({
    url: "/api/PublishContents/getofflinemembers",
    method: "get",
    params
  })
}

export function GetFileToken(params) { //获取授权token
  return request({
    url: '/api/PublishContents/GetFileToken',
    method: 'get',
    params
  })
}
export function AuditState(params) { //获取授权token
  return request({
    url: '/api/PublishContents/AuditState',
    method: 'get',
    params
  })
}