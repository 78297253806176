import Vue from "vue"
import VueRouter from "vue-router"
import HomeView from "../views/HomeView.vue"
import login from "../views/login.vue"

const Page = "页面管理";
const Activity = "活动管理";
const Article = "发布管理";
const Order = "订单管理";
const Manage = "管理员管理";
const Record = "聊天管理";
const User = "用户管理";
const systemset = "系统设置";

const routes = [{ //登录
  path: "/login",
  hidden: true, //不在导航列表中显示
  name: "登录",
  component: login,
},
{ //首页
  path: "/",
  component: HomeView,
  children: [{
    path: '/',
    name: "首页统计",
    meta: {
      title: ""
    },
    component: (resolve) =>
      require(["@/views/index.vue"], resolve),
  }]
},
{ //管理员管理
  path: "/Manage",
  component: HomeView,
  children: [{
    path: "/manage/managelist",
    name: Manage,
    meta: {
      title: "管理员列表"
    },
    component: (resolve) =>
      require(["@/views/manage/managelist.vue"], resolve),
  },
  {
    path: "/manage/openrecord",
    name: Manage,
    meta: {
      title: "操作记录"
    },
    component: (resolve) =>
      require(["@/views/manage/openrecord.vue"], resolve),
  }
  ]
},
{ //页面管理
  path: "/Page",
  component: HomeView,
  children: [{
    path: "/page/chatset",
    name: Page,
    meta: {
      title: "聊天设置"
    },
    component: (resolve) =>
      require(["@/views/page/chatset.vue"], resolve),
  },
  {
    path: "/page/carouselset",
    name: Page,
    meta: {
      title: "轮播设置"
    },
    component: (resolve) =>
      require(["@/views/page/carouselset.vue"], resolve),
  },
  {
    path: "/page/pageset",
    name: Page,
    meta: {
      title: "页面设置"
    },
    component: (resolve) =>
      require(["@/views/page/pageset.vue"], resolve),
  }
  ]
},
{ //发布管理
  path: "/article",
  component: HomeView,
  children: [{
    path: "/article/articlelist",
    name: Article,
    meta: {
      title: "发布列表"
    },
    component: (resolve) =>
      require(["@/views/article/articlelist.vue"], resolve),
  }]
},
{ //活动管理
  path: "/activity",
  component: HomeView,
  children: [{
    path: "/activity/activityset",
    name: Activity,
    meta: {
      title: "活动设置"
    },
    component: (resolve) =>
      require(["@/views/activity/activityset.vue"], resolve),
  }]
},
{ //订单管理
  path: "/orders",
  component: HomeView,
  children: [{
    path: "/orders/orderslist",
    name: Order,
    meta: {
      title: "订单列表"
    },
    component: (resolve) =>
      require(["@/views/orders/orderslist.vue"], resolve),
  }, {
    path: "/orders/reconciliation",
    name: Order,
    meta: {
      title: "对账列表"
    },
    component: (resolve) =>
      require(["@/views/orders/reconciliation.vue"], resolve),
  }, {
    path: "/orders/performancelist",
    name: Order,
    meta: {
      title: "优惠码列表"
    },
    component: (resolve) =>
      require(["@/views/orders/performancelist.vue"], resolve),
  }, {
    path: "/orders/deduction",
    name: Order,
    meta: {
      title: "扣费列表"
    },
    component: (resolve) =>
      require(["@/views/orders/deduction.vue"], resolve),
  }, {
    path: "/orders/rechargeset",
    name: Order,
    meta: {
      title: "充值设置"
    },
    component: (resolve) =>
      require(["@/views/orders/rechargeset.vue"], resolve),
  }, {
    path: "/orders/deductionrule",
    name: Order,
    meta: {
      title: "扣费规则"
    },
    component: (resolve) =>
      require(["@/views/orders/deductionrule.vue"], resolve),
  }]
},
{ //系统设置
  path: "/systemset",
  component: HomeView,
  children: [{
    path: "/systemset/set",
    name: systemset,
    meta: {
      title: "设置"
    },
    component: (resolve) =>
      require(["@/views/systemset/set.vue"], resolve),
  }]
},
{ //合同管理
  path: "/contract",
  component: HomeView,
  children: [{
    path: "/contract/contractset",
    name: systemset,
    meta: {
      title: "合同设置"
    },
    component: (resolve) =>
      require(["@/views/contract/contractset.vue"], resolve),
  },
  {
    path: "/contract/userscontractList",
    name: systemset,
    meta: {
      title: "用户合同"
    },
    component: (resolve) =>
      require(["@/views/contract/userscontractlist.vue"], resolve),
  }
  ]
},
{ //用户管理
  path: "/users",
  component: HomeView,
  children: [{
    path: "/users/userslist",
    name: User,
    meta: {
      title: "用户列表"
    },
    component: (resolve) =>
      require(["@/views/users/userslist.vue"], resolve),
  },
  {
    path: "/users/userslistrecyclebin",
    name: User,
    meta: {
      title: "用户回收列表"
    },
    component: (resolve) =>
      require(["@/views/users/userslistrecyclebin.vue"], resolve),
  }, {
    path: "/user/wechatpushpecord",
    name: User,
    meta: {
      title: "微信推送消息"
    },
    component: (resolve) =>
      require(["@/views/users/wechatpushrecord.vue"], resolve),
  }
  ]
},
{ //聊天管理
  path: "/record",
  component: HomeView,
  children: [{
    path: "/record/chatrecord",
    name: Record,
    meta: {
      title: "聊天记录"
    },
    component: (resolve) =>
      require(["@/views/record/chatrecord.vue"], resolve),
  }, {
    path: "/record/videorecord",
    name: Record,
    meta: {
      title: "视频通话"
    },
    component: (resolve) =>
      require(["@/views/record/videorecord.vue"], resolve),
  }, {
    path: "/record/voicecall",
    name: Record,
    meta: {
      title: "语音通话"
    },
    component: (resolve) =>
      require(["@/views/record/voicecall.vue"], resolve),
  }, {
    path: "/record/customerserviceresponse",
    name: Record,
    meta: {
      title: "客服回复"
    },
    component: (resolve) =>
      require(["@/views/record/customerserviceresponse.vue"], resolve),
  }, {
    path: "/record/pushinfo",
    name: Record,
    meta: {
      title: "推送消息"
    },
    component: (resolve) =>
      require(["@/views/record/pushinfo.vue"], resolve),
  }, {
    path: "/record/rule",
    name: Record,
    meta: {
      title: "规则管理"
    },
    component: (resolve) =>
      require(["@/views/record/rule.vue"], resolve),
  }]
}
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
});

//避免重复重定向路由
const originalPush = VueRouter.prototype.push
// console.log(originalPush.name, "避免重复重定向路由")
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

export default router
