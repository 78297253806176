import Cookies from 'js-cookie'

// const VUE_APP_AUTH_KEY = process.env.VUE_APP_AUTH_KEY


export function getToken(key) {
  return Cookies.get(key)
}

export function setToken(key,token) {
  return Cookies.set(key, token)
}

export function removeToken(key) {
  return Cookies.remove(key)
}

export function SetLocalStorage(name, value) {
  return localStorage.setItem(name, value);
}

export function GetLocalStorage(name) {
  return localStorage.getItem(name);
}

export function RemoveLocalStorage(name) {
  return localStorage.removeItem(name);
}

