<template>
  <div class="Container">
    <div class="Main">
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <div style="
            margin-left: 50px;
            width: 100%;
            text-align: center;
            font-size: 180%;
            margin-bottom: 8px;
            line-height: 50px;
            height: 50px;
          ">
          <p style="font-size: 1.5rem">欢迎使用婚介系统</p>
        </div>
        <el-form-item label="账号" prop="adminname">
          <el-input type="text" v-model="ruleForm.adminname" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="ruleForm.password" autocomplete="off" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">登录</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
          <!-- <el-button type="text">忘记密码?</el-button> -->
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { Login } from "@/api/webapi";
import { setToken, SetLocalStorage } from "@/common/auth";

export default {
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("年龄不能为空"));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error("请输入数字值"));
        } else {
          if (value < 18) {
            callback(new Error("必须年满18岁"));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入账号"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        adminname: "",
        password: "",
      },
      rules: {
        adminname: [{ validator: validatePass, trigger: "blur" }],
        password: [{ validator: validatePass2, trigger: "blur" }],
      },
      fullscreenLoading: false,
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.login();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    async login() {
      let data = {
        accountName: this.ruleForm.adminname,
        passWord: this.$md5(this.ruleForm.password),
      };
      // this.fullscreenLoading = true;
      const loading = this.$loading({
        lock: true,
        text: "登录中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let result = await Login(data);
      if (result.code == 200) {
        setTimeout(() => {
          // this.fullscreenLoading = false;
          setToken("auth_token", result.data.token);
          SetLocalStorage("accountName", result.data.accountName);
          SetLocalStorage("onlyId", result.data.onlyId);
          SetLocalStorage("roleId", result.data.roleId);
          SetLocalStorage("loginTime", result.data.loginTime);
          this.$router.push({ path: "/" });
          this.$message({
            type: "success",
            message: result.message,
          });
          loading.close();
        }, 1100);

        return;
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
        loading.close();
        return;
      }
    },
  },
};
</script>
<style>
.Container {
  width: 100%;
  height: 100%;
  /* background: darkgrey; */
  background-image: url("../img/BingWallpaper.jpg");
  background-size: cover;
}

.Main {
  width: 50%;
  height: 50%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 23%;
}

.demo-ruleForm {
  /* width: 38%; */
  margin-left: 25%;
  /*  padding-top: 5%; */
  padding-right: 7%;
  position: absolute;
  /*  height: 210px; */
  background: rgba(111, 125, 138, 0.694);
  top: 23.3%;
}

.el-form-item__label {
  padding: 1px 15px 0 0 !important;
  color: #fff;
}

.el-button--text {
  color: #bdb7b7;
}

@media screen and (max-width: 480px) {
  .demo-ruleForm {
    width: 70%;
    margin-left: 11.5%;
    padding-top: 5%;
    padding-right: 7%;
    position: absolute;
    height: 210px;
    background: rgba(111, 125, 138, 0.694);
    top: 23.3%;
  }

  .Main {
    width: 100%;
  }
}

@media screen and (max-width: 370px) {
  .demo-ruleForm {
    height: 56%;
  }
}

@media screen and (max-width: 410px) {
  .demo-ruleForm {
    height: 210px;
  }
}

@media screen and (max-width: 390px) {
  .demo-ruleForm {
    height: 210px;
  }
}

@media screen and (max-width: 324px) {
  .demo-ruleForm {
    height: 210px;
    width: 70%;
    margin-left: 11.5%;
    padding-top: 5%;
    padding-right: 7%;
    position: absolute;
    background: rgba(111, 125, 138, 0.694);
    top: 23.3%;
  }
}

@media screen and (max-width: 820px) {
  .demo-ruleForm {
    width: 80%;
    margin-left: 9%;
    padding-top: 5%;
    padding-right: 7%;
    position: absolute;
    height: 210px;
    background: rgba(111, 125, 138, 0.694);
    top: 23.3%;
  }
}

@media screen and (max-width: 768px) {
  .demo-ruleForm {
    width: 80%;
    margin-left: 9%;
    padding-top: 5%;
    padding-right: 7%;
    position: absolute;
    height: 210px;
    background: rgba(111, 125, 138, 0.694);
    top: 23.3%;
  }
}

@media screen and (max-width: 1024px) {
  .demo-ruleForm {
    width: 60%;
    margin-left: 16%;
    padding-top: 5%;
    padding-right: 7%;
    position: absolute;
    height: 210px;
    background: rgba(111, 125, 138, 0.694);
    top: 23.3%;
  }
}

@media screen and (max-width: 1280px) {
  .demo-ruleForm {
    width: 61%;
    margin-left: 16%;
    padding-top: 5%;
    padding-right: 7%;
    position: absolute;
    height: 64%;
    background: rgba(111, 125, 138, 0.694);
    top: 23.3%;
  }
}

@media screen and (max-width: 412px) {
  .demo-ruleForm {
    width: 61%;
    margin-left: 16%;
    padding-top: 5%;
    padding-right: 7%;
    position: absolute;
    height: 210px;
    background: rgba(111, 125, 138, 0.694);
    top: 23.3%;
  }
}

@media screen and (max-width: 412px) {
  .demo-ruleForm {
    width: 93%;
    margin-left: 0%;
    padding-top: 16%;
    padding-right: 7%;
    position: absolute;
    height: 210px;
    background: rgba(111, 125, 138, 0.694);
    top: 24.3%;
  }
}

@media screen and (max-width: 540px) {
  .demo-ruleForm {
    width: 100%;
    margin-left: 0%;
    padding-top: 16%;
    padding-right: 7%;
    position: absolute;
    height: 210px;
    background: rgba(111, 125, 138, 0.694);
    top: 24.3%;
  }
}

@media screen and (max-width: 768px) {
  .demo-ruleForm {
    width: 70%;
    margin-left: 13%;
    padding-top: 5%;
    padding-right: 7%;
    position: absolute;
    height: 68%;
    background: rgba(111, 125, 138, 0.694);
    top: 24.3%;
  }
}
</style>
