//图片base64
//file
//w:宽
//h:高
export function getBase64(file, w, h) {
  return new Promise(function (resolve, reject) {
    var canvas = document.createElement('canvas'); // 创建Canvas对象(画布)
    var context = canvas.getContext('2d');
    var img = new Image();
    var reader = new FileReader();
    reader.readAsDataURL(file.file);

    reader.onload = (e) => {
      console.log(e);
      img.src = e.target.result;

      var b = (img.onload = () => {
        canvas.width = w;
        canvas.height = h;
        context.drawImage(img, 0, 0, w, h);
        file.content = canvas.toDataURL(file.file.type, 0.95); // 0.92为默认压缩质量
        resolve(file.content);
      });
    };
  });
}
