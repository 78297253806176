<template>
  <!-- <el-aside width="10.5vw" style="margin-top: 4.208vw !important">  margin-top: 4.208vw !important-->
  <el-col :span="12" style="position: fixed; z-index: 999">
    <el-menu
      unique-opened
      default-active="2"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      background-color="#545c64"
      style="width: 200.9px"
      text-color="#fff"
      active-text-color="#ffd04b"
      ope
    >
      <el-submenu
        v-for="iteme in nmenus"
        :index="iteme.index"
        :key="iteme.index"
        v-if="iteme.indexCheck"
      >
        <template slot="title">
          <i :class="iteme.icon"></i>
          <span>{{ iteme.title }}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item
            v-for="(mitem, index) in iteme.menuitemGroups"
            :key="index"
            @click="toRouter(mitem)"
            v-if="mitem.indexCheck"
            ><i :class="mitem.icon"></i>{{ mitem.title }}</el-menu-item
          >
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </el-col>
  <!-- </el-aside> -->
</template>
<script>
import {
  GetModuls,
  GetTwoModulDataPermissions,
  // GetOneModulPermissions,
  AddSysTakeNotes,
} from "@/api/webapi";
import { SetLocalStorage, GetLocalStorage } from "@/common/auth";

export default {
  data() {
    return {
      nmenus: [
        // 管理员管理
        // {
        //   index: "0",
        //   title: "首页",
        //   icon: "el-icon-s-home",
        //   menuitemgroup: [
        //     {
        //       index: "0-1",
        //       title: "首页统计",
        //       router: "/",
        //       icon: "el-icon-pie-chart",
        //     },
        //   ],
        // }, // 管理员管理
        // {
        //   index: "1",
        //   title: "管理员管理",
        //   icon: "el-icon-s-management",
        //   menuitemgroup: [
        //     {
        //       index: "1-1",
        //       router: "/manage/manageList",
        //       title: "管理员列表",
        //       icon: "el-icon-s-management",
        //     },
        //     {
        //       index: "1-2",
        //       router: "/manage/openrecord",
        //       title: "操作记录",
        //       icon: "el-icon-edit",
        //     },
        //   ],
        // },
        // //页面管理
        // {
        //   index: "2",
        //   title: "页面管理",
        //   icon: "el-icon-house",
        //   menuitemgroup: [
        //     {
        //       index: "2-1",
        //       router: "/page/carouselset",
        //       title: "轮播设置",
        //       icon: "el-icon-setting",
        //     },
        //     {
        //       index: "2-2",
        //       router: "/page/pageset",
        //       title: "页面设置",
        //       icon: "el-icon-setting",
        //     },
        //   ],
        // },
        // //用户管理
        // {
        //   index: "3",
        //   title: "用户管理",
        //   icon: "el-icon-user",
        //   menuitemgroup: [
        //     {
        //       index: "3-1",
        //       router: "/users/userslist",
        //       title: "用户列表",
        //       icon: "el-icon-user",
        //     },
        //   ],
        // },
        // //订单管理
        // {
        //   index: "6",
        //   title: "订单管理",
        //   icon: "el-icon-s-order",
        //   menuitemgroup: [
        //     {
        //       index: "6-4",
        //       router: "/orders/orderslist",
        //       title: "订单列表",
        //       icon: "el-icon-s-order",
        //     },
        //     {
        //       index: "6-5",
        //       router: "/orders/deduction",
        //       title: "扣费列表",
        //       icon: "el-icon-bank-card",
        //     },
        //     {
        //       index: "6-6",
        //       router: "/orders/reconciliation",
        //       title: "对账列表",
        //       icon: "el-icon-collection-tag",
        //     },
        //   ],
        // },
        // //合同管理
        // {
        //   index: "8",
        //   title: "合同管理",
        //   icon: "el-icon-files",
        //   menuitemgroup: [
        //     {
        //       index: "8-1",
        //       router: "/contract/contractset",
        //       title: "合同设置",
        //       icon: "el-icon-setting",
        //     },
        //     {
        //       index: "8-2",
        //       router: "/contract/userscontractlist",
        //       title: "用户合同",
        //       icon: "el-icon-user",
        //     },
        //   ],
        // },
        // //活动管理
        // {
        //   index: "9",
        //   title: "活动管理",
        //   icon: "el-icon-present",
        //   menuitemgroup: [
        //     {
        //       index: "9-1",
        //       router: "/activity/activityset",
        //       title: "活动设置",
        //       icon: "el-icon-present",
        //     },
        //   ],
        // },
        // //聊天管理
        // {
        //   index: "7",
        //   title: "聊天管理",
        //   icon: "el-icon-s-promotion",
        //   menuitemgroup: [
        //     {
        //       index: "7-1",
        //       router: "/record/chatrecord",
        //       title: "聊天记录",
        //       icon: "el-icon-chat-dot-round",
        //     },
        //     {
        //       index: "7-2",
        //       router: "/record/voicecall",
        //       title: "语音通话",
        //       icon: "el-icon-phone",
        //     },
        //     {
        //       index: "7-3",
        //       router: "/record/videorecord",
        //       title: "视频通话",
        //       icon: "el-icon-video-camera",
        //     },
        //     {
        //       index: "7-4",
        //       router: "/record/rule",
        //       title: "规则管理",
        //       icon: "el-icon-thumb",
        //     },
        //   ],
        // },
        // //发布管理
        // {
        //   index: "5",
        //   title: "发布管理",
        //   icon: "el-icon-tickets",
        //   menuitemgroup: [
        //     {
        //       index: "5-1",
        //       router: "/article/articleList",
        //       title: "发布列表",
        //       icon: "el-icon-tickets",
        //     },
        //   ],
        // },
        // //系统设置
        // {
        //   index: "10",
        //   title: "系统设置",
        //   icon: "el-icon-setting",
        //   menuitemgroup: [
        //     {
        //       index: "10-1",
        //       router: "/systemset/Set",
        //       title: "设置",
        //       icon: "el-icon-setting",
        //     },
        //   ],
        // },
      ],
      dataPermissinons: [],
    };
  },
  //首次加载
  created() {
    this.getmudel();
    this.getModuls();
    //监听一级模块权限
    // setTimeout(() => {
    //   this.getModuls();
    // }, 3000);
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    //路由进入
    toRouter(ritem) {
      // this.getModuls();
      this.nmenus.forEach((z) => {
        z.menuitemGroups.forEach((g) => {
          if (g.index == ritem.index) {
            if (g.indexCheck) {
              this.getTwoModulDataPermissions(g.index);
              this.$router.push({ path: ritem.router });
              return;
            } else {
              this.$router.push({ path: "/" });
              return;
            }
          }
        });
      });
      this.addSysTakeNotes(ritem);
    },
    //记录操作模块
    async addSysTakeNotes(itme) {
      const data = {
        manageId: GetLocalStorage("onlyId"),
        manageName: GetLocalStorage("accountName"),
        modelName: itme.title,
        modulRoot: itme.router,
        loginTime: GetLocalStorage("loginTime"),
      };
      const result = await AddSysTakeNotes(data);
      if ((result.code = 200)) {
      }
    },
    //获取一级二级模块数据权限
    // async getOneTwoModulPermissions() {
    //   const roleId = GetLocalStorage("roleId");
    //   const params = {
    //     roleId: roleId,
    //   };
    //   const result = await GetOneTwoModulPermissions(params);
    //   if (result.code == 200) {
    //     this.oneModulPermissinons = result.data.oneTwoModulPermissions;
    //   }
    // },
    //获取二级模块数据权限
    async getTwoModulDataPermissions(moduId) {
      const roleId = GetLocalStorage("roleId");
      const params = {
        roleId: roleId,
        moduId: moduId,
      };
      const result = await GetTwoModulDataPermissions(params);
      if (result.code == 200) {
        SetLocalStorage(
          "permissions",
          JSON.stringify(result.data.dataPermissions)
        );
      }
    },

    //获取菜单内存模块
    getmudel() {
      const nmenus = JSON.parse(GetLocalStorage("nmenus"));
      if (nmenus != undefined) {
        this.nmenus = nmenus;
        // console.log("nmenus:", nmenus);
      }
    },

    //获取系统菜单模块管理
    async getModuls() {
      const roleId = GetLocalStorage("roleId");
      const params = {
        roleId: roleId,
      };
      let result = await GetModuls(params);
      if ((result.code = 200)) {
        var mvs = JSON.stringify(result.data.modulsViews);
        const menus = GetLocalStorage("nmenus");

        if (mvs != menus) {
          // console.log("mvs: ", mvs);
          // console.log("menus: ", menus);
          this.nmenus = result.data.modulsViews;
          SetLocalStorage("nmenus", JSON.stringify(this.nmenus));
          this.getmudel();
        } else {
          console.log("忽略");
        }
      }
    },
  },
};
</script>

<style scoped>
.el-submenu {
  width: 201px;
}
.el-col-12 {
  width: 201px;
  background: rgb(84, 92, 100);
}
>>> .el-submenu .el-menu-item {
  /* width: 197; */
  color: #ffffff !important;
}
.el-menu-item i {
  color: #909399 !important;
}
</style>
