<template>
  <section class="app-main clearBoth">
    <div style="height: 54px;">
      <el-main style="
      position: fixed;
      z-index: 999;
      background: #f0f0f2;
      width: 100%;
  ">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item v-for="(item, index) in levelList" :key="index + 1">{{
            item.name
          }}</el-breadcrumb-item>

          <el-breadcrumb-item v-for="(item, index) in levelList" :to="item.path" :key="index + 2">{{ item.meta.title
          }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-main>
    </div>
    <router-view></router-view>
  </section>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      levelList: [],
    };
  },
  watch: {
    //当值变化时，watch监听到并且执行
    $route() {
      this.getBreadcrumb();
    },
  },
  created() {
    setTimeout(() => {
      this.getBreadcrumb();
    }, 800);

  },
  //方法
  methods: {
    getBreadcrumb() {
      let matched = this.$route.matched.filter((item) => item.name);
      this.levelList = matched;
      console.log(this.levelList, "路由面包屑");
    },
  },
};
</script>

<style>
.clearBoth {
  width: 88.9%;
}
</style>