import axios from 'axios'
import {
  getToken
} from '@/common/auth'
import store from '@/store'

import router from '@/router'
import {
  MessageBox
} from 'element-ui'
// const VUE_APP_AUTH_KEY = process.env.VUE_APP_AUTH_KEY

const httpRequest = axios.create({
  baseURL: 'https://www.guisuzhenhun.com/sys/',
  //baseURL: process.env.VUE_APP_BASE_API = "https://localhost:5001",
  timeout: process.env.VUE_APP_REQUEST_TIMEOUT
})

/**
 *
 *  @param {*} config.下的options字段
 *  @param {*} showLoading 是否显示全屏loading 预留
 *  @param {*} showMessage 业主状态码错误是否提示全屏message
 *  @param {*} message 自定义message信息
 *  @param {*} action 业务动作，根据业务动作提示不同信息，所有业务动作在message.js里定义
 */
// var elLoading;

httpRequest.interceptors.request.use(
  (config) => {
    const options = config.options || {}
    const {
      showLoading
    } = options
    if (showLoading) {
      // elLoading = ElLoading.service({
      //   fullscreen: true,
      //   lock: true,
      //   text: "正在加载",
      //   background: "rgba(255, 255, 255, 0.5)",
      // });
    }
    if (store.getters.token) {
      // config.headers[VUE_APP_AUTH_KEY] = getToken()
    }
    const {
      method
    } = config
    if (!config.json) {
      if (config.filterEmptyFlag === undefined) {
        config.filterEmptyFlag = true // 默认过滤空值
      }
      if (method === 'post') {
        config.data = config.data ?
          config.filterEmptyFlag ?
            filterEmpty(config.data) :
            config.data : {}
      } else {
        config.params = config.params ? filterEmpty(config.params) : {}
      }
      config.headers.common['Authorization'] = "Bearer " + getToken("auth_token");
      config.headers['Content-Type'] =
        'application/x-www-form-urlencoded; charset=UTF-8'
      config.data = queryParse(config.data)
    } else {

      config.headers.common['Authorization'] = "Bearer " + getToken("auth_token");
      // config.params = config.params ? filterEmpty(config.params) : {}
      config.data = config.data ? config.data : {}
      config.headers['Content-Type'] = 'application/json'
    }
    return config
  },
  (error) => {
    // elLoading.close();
    Promise.reject(error)
  }
)

httpRequest.interceptors.response.use(
  (response) => {
    // elLoading.close();
    const res = response.data
    // if the custom code is not 200, it is judged as an error.
    if (res.code == 401) {

      // router.push({
      //   path: "/login"
      // });
    }
    if (res.code != 200) {
      // 401: Illegal token; 402: Other clients logged in; 403: Token expired;
      /* let username = localStorage.getItem("username"); */
      // if (res.state === 401 || res.state === 402 || res.state === 403) {
      //   ElMessageBox.confirm("登录已过期,是否重新登录", "?", {
      //     confirmButtonText: "重新登录",
      //     cancelButtonText: "取消",
      //     type: "warning",
      //   }).then(() => {
      //       router.push({path:"/"});
      //   });
      //   return;
      // }
      // else{
      //   return Promise.resolve(res);
      // }
      // return Promise.reject(new Error(res.exceptionMessage || "Error"));
      return Promise.resolve(res)
    } else {
      return Promise.resolve(res)
    }
  },
  (error) => {
    console.log("err" + error); // for debug
    if (error.response.status == 401) {
      MessageBox.confirm('登录已过期,是否重新登录', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        router.push({
          path: "/login"
        });
        return;
      }).catch(() => {

      });
      return;
    }
    if (error.response.status == 201) {
      router.push({
        path: "/login"
      });
      return;
    }
    return Promise.reject(error)
  }
)

export function queryParse(query) {
  const res = []
  for (const k in query) {
    res.push(`${encodeURIComponent(k)}=${encodeURIComponent(query[k])}`)
  }
  return res.join('&')
}

export function filterEmpty(obj) {
  return Object.keys(obj).reduce((total, next) => {
    const val = obj[next]
    if (val || typeof val === 'number' || typeof val === 'boolean') {
      total[next] = val
    }
    return total
  }, {})
}

export default httpRequest
